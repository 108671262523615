<template>
    <div id="nt_wrapper">

        <Header/>

        <div v-if="product" id="nt_content">
            <div class="sp-single sp-single-1 des_pr_layout_1 mb__60">

                <!-- breadcrumb -->
                <div class="bgbl pt__20 pb__20 lh__1">
                    <div class="container">
                        <div class="row al_center">
                            <div class="col">
                                <nav class="sp-breadcrumb">
                                    <a href="/">首页</a><i class="facl facl-angle-right"></i>
                                    <a v-for="(dt, index) in product.breadcrumbs" :key="index" :href="'/product/list/' + dt.product_category_id">{{dt.title}}</a>
                                    <i class="facl facl-angle-right"></i>{{product.title}}
                                </nav>
                            </div>
                            <div class="col-auto flex al_center">
                                <a v-if="product.product_prev" :href="'/product/list/' + product.product_prev.product_id" class="pl__5 pr__5 fs__18 cd chp ttip_nt tooltip_bottom_left"><i class="las la-angle-left"></i><span class="tt_txt">{{product.product_prev.title}}</span></a>
                                <a v-if="product.category" :href="'/product/list/' + product.category.product_category_id" class="pl__5 pr__5 fs__20 cd chp ttip_nt tooltip_bottom_left"><i class="fwb iccl iccl-grid fs__15"></i><span class="tt_txt">返回{{product.category.title}}列表</span></a>
                                <a v-if="product.product_next" :href="'/product/list/' + product.product_next.product_id" class="pl__5 pr__5 fs__18 cd chp ttip_nt tooltip_bottom_left"><i class="las la-angle-right"></i><span class="tt_txt">{{product.product_next.title}}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end breadcrumb -->

                <div class="container container_cat cat_default">
                    <div class="row product mt__40">
                        <div class="col-md-12 col-12 thumb_left">
                            <div class="row mb__20 pr_sticky_content">

                                <!-- product thumbnails -->
                                <div v-if="product.img_url_list" class="col-md-6 col-12 pr product-images img_action_zoom pr_sticky_img kalles_product_thumnb_slide">
                                    <div class="row theiaStickySidebar">
                                        <div class="col-12 col-lg col_thumb">
                                            <div class="p-thumb p-thumb_ppr images sp-pr-gallery equal_nt nt_contain ratio_imgtrue position_8 nt_slider pr_carousel" data-flickity='{"initialIndex": ".media_id_0","fade":true,"draggable":">1","cellAlign": "center","wrapAround": true,"autoPlay": false,"prevNextButtons":true,"adaptiveHeight": true,"imagesLoaded": false, "lazyLoad": 0,"dragThreshold" : 6,"pageDots": false,"rightToLeft": false }'>
                                                <div v-for="(dt, index) in product.img_url_list" :key="index" :class="'img_ptw p_ptw p-item sp-pr-gallery__img w__100 nt_bg_lz lazyload padding-top__100 media_id_' + index" :data-mdid="index" data-height="800" data-width="800" data-ratio="1" data-mdtype="image" :data-src="dt" :data-bgset="dt" :data-cap="product.title"></div>
                                            </div>
                                            <div class="p_group_btns pa flex">
                                                <button class="br__40 tc flex al_center fl_center show_btn_pr_gallery ttip_nt tooltip_top_left"><i class="las la-expand-arrows-alt"></i><span class="tt_txt">点击放大</span></button>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-auto col_nav nav_medium t4_show">
                                            <div class="p-nav ratio_imgtrue row equal_nt nt_cover position_8 nt_slider pr_carousel" data-flickityjs='{"initialIndex": ".media_id_0","cellSelector": ".n-item:not(.is_varhide)","cellAlign": "left","asNavFor": ".p-thumb","wrapAround": true,"draggable": ">1","autoPlay": 0,"prevNextButtons": 0,"percentPosition": 1,"imagesLoaded": 0,"pageDots": 0,"groupCells": 3,"rightToLeft": false,"contain":  1,"freeScroll": 0}'></div>
                                            <button type="button" aria-label="Previous" class="btn_pnav_prev pe_none"><i class="las la-angle-up"></i></button>
                                            <button type="button" aria-label="Next" class="btn_pnav_next pe_none"><i class="las la-angle-down"></i></button>
                                        </div>
                                        <div class="dt_img_zoom pa t__0 r__0 dib"></div>
                                    </div>
                                </div>
                                <!-- end product thumbnails -->

                                <!-- product info -->
                                <div class="col-md-6 col-12 product-infors pr_sticky_su">
                                    <div class="theiaStickySidebar">
                                        <div class="kalles-section-pr_summary kalles-section summary entry-summary mt__30">
                                            <h1 class="product_title entry-title fs__16">{{product.title}}<template v-for="(dt, index) in options.spec_list" :key="index">{{dt ? ' ' + dt.title : ''}}</template></h1>
                                            <div v-if="product.slogan" class="pr_short_des">
                                                <p class="mg__0" v-html="product.slogan"></p>
                                            </div>
                                            <div class="flex wrap fl_between al_center price-review">
                                                <p v-if="website.show_price" class="price_range" id="price_ppr">
                                                    <span style="font-size: 12px;">¥</span>
                                                    <template v-if="options.sku">{{options.sku.price}}</template>
                                                    <template v-else>{{product.min_price}}<template v-if="product.min_price != product.max_price">-{{product.max_price}}</template></template>
                                                </p>
                                                <a href="#tab_reviews_product" class="rating_sp_kl dib">
                                                    <div class="kalles-rating-result">
                                                        <span class="kalles-rating-result__pipe">
                                                            <span class="kalles-rating-result__start kalles-rating-result__start--big"></span>
                                                            <span class="kalles-rating-result__start kalles-rating-result__start--big"></span>
                                                            <span class="kalles-rating-result__start kalles-rating-result__start--big"></span>
                                                            <span class="kalles-rating-result__start kalles-rating-result__start--big"></span>
                                                            <span class="kalles-rating-result__start kalles-rating-result__start--big active"></span>
                                                        </span>
                                                        <span v-if="false" class="kalles-rating-result__number">(12 评论)</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div v-if="product.description" class="pr_short_des">
                                                <p class="mg__0" v-html="product.description"></p>
                                            </div>
                                            <div class="btn-atc atc-slide btn_des_1 btn_txt_3">
                                                <div id="callBackVariant_ppr">
                                                    <div class="variations mb__40 style__rectangle size_medium">
                                                        <div v-for="(dt, index) in product.spec_list" :key="index" class="swatch is-label kalles_swatch_js">
                                                            <h4 class="swatch__title">{{dt.title}}: <span class="nt_name_current user_choose_js">{{options.spec_list[index] ? options.spec_list[index].title : ''}}</span></h4>
                                                            <ul class="swatches-select swatch__list_pr d-flex">
                                                                <template v-for="(dt2, index2) in dt.children" :key="index2">
                                                                    <li @click="clickSpec(index, index2)" :class="['nt-swatch swatch_pr_item', {'is-selected': false}]" :data-escape="dt2.title"><span class="swatch__value_pr">{{dt2.title}}</span></li>
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="nt_cart_form variations_form variations_form_ppr">
                                                        <div class="variations_button in_flex column w__100 buy_qv_false">
                                                            <div class="flex wrap">
                                                                <div class="quantity pr mr__10 order-1 qty__true d-inline-block" id="sp_qty_ppr">
                                                                    <input type="number" class="input-text qty text tc qty_pr_js qty_cart_js" name="quantity" value="1">
                                                                    <div class="qty tc fs__14">
                                                                        <button type="button" class="plus db cb pa pd__0 pr__15 tr r__0"><i class="facl facl-plus"></i></button>
                                                                        <button type="button" class="minus db cb pa pd__0 pl__15 tl l__0"><i class="facl facl-minus"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div v-if="false" :class="['nt_add_w ts__03 pa order-3', {'like_add': my}]">
                                                                    <a v-if="my" href="#" @click.prevent="clickLikeAdd(product.product_id)" class="wishlistadd cb chp ttip_nt tooltip_top_left"><span class="tt_txt">添加到喜欢</span><i class="facl facl-heart-o"></i></a>
                                                                    <a v-else href="#" data-id="#nt_login_canvas" class="wishlistadd cb chp ttip_nt tooltip_top_left push_side"><span class="tt_txt">添加到喜欢</span><i class="facl facl-heart-o"></i></a>
                                                                </div>
                                                                <button type="submit" data-time="6000" data-ani="shake" class="single_add_to_cart_button button truncate w__100 mt__20 order-4 d-inline-block animated"><span class="txt_add ">在线购买</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" id="trust_seal_ppr" class="pr_trust_seal tl_md tc"><img class="img_tr_s1 lazyload w__100 max-width__330px" src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%202244%20285%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" alt="" data-srcset="/images/single-product/trust_img2.png"/></div>
                                            <div v-if="false" class="extra-link mt__35 fwsb">
                                                <a class="ajax_pp_js cd chp mr__20" href="#" data-id="#popup-size-guide">常见问题</a>
                                                <a class="ajax_pp_js cd chp mr__20" href="#" data-id="#popup-delivery-and-return">运输 &amp; 售后</a>
                                                <a class="ajax_pp_js cd chp" href="#" data-id="#popup-ask-a-question">问题咨询</a>
                                            </div>
                                            <div class="product_meta">
                                                <span class="sku_wrapper"><span class="cb">编号：</span><span class="sku value cg d-inline-block">{{product.product_id}}</span><span class="cb ml__40">品牌：</span><span class="sku value cg d-inline-block">{{product.brand.title}}</span></span>
                                                <span class="sku_wrapper"></span>
                                                <span class="posted_in">
                                                    <span class="cb">分类：</span><a href="/product/list" class="cg">全部产品</a>
                                                    <template v-for="(dt, index) in product.breadcrumbs" :key="index">, <a :href="'/product/list/' + dt.product_category_id" class="cg">{{dt.title}}</a></template>
                                                    <template v-for="(dt, index) in product.extend_category_list" :key="index">, <a :href="'/product/list/' + dt.product_category_id" class="cg">{{dt.title}}</a></template>
                                                </span>
                                                <span v-if="false" class="tagged_as"><span class="cb">标签：</span><a href="shop-filter-options.html" class="cg">Color Black</a>, <a href="shop-filter-options.html" class="cg">Color Grey</a>, <a href="shop-filter-options.html" class="cg">Color Pink</a>, <a href="shop-filter-options.html" class="cg">Price $7-$50</a>, <a href="shop-filter-options.html" class="cg">Size L</a>, <a href="shop-filter-options.html" class="cg">Size M</a>, <a href="shop-filter-options.html" class="cg">Size S</a></span>
                                            </div>
                                            <div v-if="false" class="social-share tc">
                                                <div class="at-share-btn-elements kalles-social-media d-block text-left fs__0 lh__1"><a href="https://www.facebook.com/" class="at-icon-wrapper at-share-btn at-svc-facebook bg-white kalles-social-media__btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="at-icon at-icon-facebook">
                                                        <g>
                                                            <path d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z" fill-rule="evenodd"></path>
                                                        </g>
                                                    </svg>
                                                </a><a href="https://twitter.com/" class="at-icon-wrapper at-share-btn at-svc-twitter bg-white kalles-social-media__btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="at-icon at-icon-twitter">
                                                        <g>
                                                            <path d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336" fill-rule="evenodd"></path>
                                                        </g>
                                                    </svg>
                                                </a><a href="https://www.google.com/gmail/about" class="at-icon-wrapper at-share-btn at-svc-email bg-white">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="at-icon at-icon-email kalles-social-media__btn">
                                                        <g>
                                                            <g fill-rule="evenodd"></g>
                                                            <path d="M27 22.757c0 1.24-.988 2.243-2.19 2.243H7.19C5.98 25 5 23.994 5 22.757V13.67c0-.556.39-.773.855-.496l8.78 5.238c.782.467 1.95.467 2.73 0l8.78-5.238c.472-.28.855-.063.855.495v9.087z"></path>
                                                            <path d="M27 9.243C27 8.006 26.02 7 24.81 7H7.19C5.988 7 5 8.004 5 9.243v.465c0 .554.385 1.232.857 1.514l9.61 5.733c.267.16.8.16 1.067 0l9.61-5.733c.473-.283.856-.96.856-1.514v-.465z"></path>
                                                        </g>
                                                    </svg>
                                                </a><a href="https://www.pinterest.com/" class="at-icon-wrapper at-share-btn at-svc-pinterest_share bg-white kalles-social-media__btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="at-icon at-icon-pinterest_share">
                                                        <g>
                                                            <path d="M7 13.252c0 1.81.772 4.45 2.895 5.045.074.014.178.04.252.04.49 0 .772-1.27.772-1.63 0-.428-1.174-1.34-1.174-3.123 0-3.705 3.028-6.33 6.947-6.33 3.37 0 5.863 1.782 5.863 5.058 0 2.446-1.054 7.035-4.468 7.035-1.232 0-2.286-.83-2.286-2.018 0-1.742 1.307-3.43 1.307-5.225 0-1.092-.67-1.977-1.916-1.977-1.692 0-2.732 1.77-2.732 3.165 0 .774.104 1.63.476 2.336-.683 2.736-2.08 6.814-2.08 9.633 0 .87.135 1.728.224 2.6l.134.137.207-.07c2.494-3.178 2.405-3.8 3.533-7.96.61 1.077 2.182 1.658 3.43 1.658 5.254 0 7.614-4.77 7.614-9.067C26 7.987 21.755 5 17.094 5 12.017 5 7 8.15 7 13.252z" fill-rule="evenodd"></path>
                                                        </g>
                                                    </svg>
                                                </a><a href="https://www.messenger.com" class="at-icon-wrapper at-share-btn at-svc-messenger bg-white kalles-social-media__btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="at-icon at-icon-messenger">
                                                        <g>
                                                            <path d="M16 6C9.925 6 5 10.56 5 16.185c0 3.205 1.6 6.065 4.1 7.932V28l3.745-2.056c1 .277 2.058.426 3.155.426 6.075 0 11-4.56 11-10.185C27 10.56 22.075 6 16 6zm1.093 13.716l-2.8-2.988-5.467 2.988 6.013-6.383 2.868 2.988 5.398-2.987-6.013 6.383z" fill-rule="evenodd"></path>
                                                        </g>
                                                    </svg>
                                                </a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product info -->

                            </div>
                        </div>
                    </div>
                </div>

                <!-- description and review -->
                <div id="wrap_des_pr">
                    <div class="container container_des">
                        <div class="kalles-section-pr_description kalles-section kalles-tabs sp-tabs nt_section">

                            <!-- tab buttons -->
                            <ul class="ul_none ul_tabs is-flex fl_center fs__16 des_mb_2 des_style_1">
                                <li class="tab_title_block active"><a class="db cg truncate pr" href="#tab_product_description">详情</a></li>
                                <li v-if="product.attr_list && product.attr_list.length > 0" class="tab_title_block"><a class="db cg truncate pr" href="#tab_additional_information">参数</a></li>
                                <li v-if="product.warranty_shipping || product.wash_care" class="tab_title_block"><a class="db cg truncate pr" href="#tab_warranty_and_shipping">运输 &amp; 售后</a></li>
                                <li v-if="false" class="tab_title_block"><a class="db cg truncate pr" href="#tab_wash_and_care">图集</a></li>
                                <li v-if="false" class="tab_title_block"><a class="db cg truncate pr" href="#tab_reviews_product">评论</a></li>
                            </ul>
                            <!-- end tab buttons -->

                            <!-- tab contents -->
                            <div class="panel entry-content sp-tab des_mb_2 des_style_1 active" id="tab_product_description">
                                <div class="js_ck_view"></div>
                                <div class="heading bgbl dn"><a class="tab-heading flex al_center fl_between pr cd chp fwm" href="#tab_product_description"><span class="txt_h_tab">详情</span><span class="nav_link_icon ml__5"></span></a></div>
                                <div class="sp-tab-content">
                                    <div class="sp-tab-content" v-html="product.detail"></div>
                                    <div class="text-center">
                                        <img v-for="(dt, index) in product.detail_img_list" :key="index" :src="dt.url" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="product.attr_list && product.attr_list.length > 0" class="panel entry-content sp-tab des_mb_2 des_style_1 dn" id="tab_additional_information">
                                <div class="js_ck_view"></div>
                                <div class="heading bgbl dn"><a class="tab-heading flex al_center fl_between pr cd chp fwm" href="#tab_additional_information"><span class="txt_h_tab">参数</span><span class="nav_link_icon ml__5"></span></a></div>
                                <div class="sp-tab-content">
                                    <table class="pr_attrs">
                                        <tbody>
                                            <tr v-for="(dt, index) in product.attr_list" :key="index" class="attr_pa_color">
                                                <th class="attr__label cb">{{dt.title}}</th>
                                                <td class="attr__value cb">
                                                    <p>
                                                        <template v-for="(dt2, index2) in dt.children" :key="index2">
                                                            <template v-if="index2 != 0">, </template>{{dt2.title}}
                                                        </template>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div v-if="product.warranty_shipping || product.wash_care" class="panel entry-content sp-tab des_mb_2 des_style_1 dn" id="tab_warranty_and_shipping">
                                <div class="js_ck_view"></div>
                                <div class="heading bgbl dn"><a class="tab-heading flex al_center fl_between pr cd chp fwm" href="#tab_warranty_and_shipping"><span class="txt_h_tab">运输 &amp; 售后</span><span class="nav_link_icon ml__5"></span></a></div>
                                <div class="sp-tab-content">
                                    <div class="warranty-content cb">
                                        <template v-if="product.warranty_shipping">
                                            <h5 class="mt-0 mb__5"><b>运输</b></h5>
                                            <div v-html="product.warranty_shipping"></div>
                                        </template>
                                        <template v-if="product.wash_care">
                                            <h5 class="mt-0 mb__5"><b>售后</b></h5>
                                            <div v-html="product.wash_care"></div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="panel entry-content sp-tab des_mb_2 des_style_1 dn" id="tab_wash_and_care">
                                <div class="js_ck_view"></div>
                                <div class="heading bgbl dn"><a class="tab-heading flex al_center fl_between pr cd chp fwm" href="#tab_wash_and_care"><span class="txt_h_tab">图集</span><span class="nav_link_icon ml__5"></span></a></div>
                                <div class="sp-tab-content">
                                    <ul class="flex ul_none fl_center">
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M8.7 30.7h22.7c.3 0 .6-.2.7-.6l4-25.3c-.1-.4-.3-.7-.7-.8s-.7.2-.8.6L34 8.9l-3-1.1c-2.4-.9-5.1-.5-7.2 1-2.3 1.6-5.3 1.6-7.6 0-2.1-1.5-4.8-1.9-7.2-1L6 8.9l-.7-4.3c0-.4-.4-.7-.7-.6-.4.1-.6.4-.6.8l4 25.3c.1.3.3.6.7.6zm.8-21.6c2-.7 4.2-.4 6 .8 1.4 1 3 1.5 4.6 1.5s3.2-.5 4.6-1.5c1.7-1.2 4-1.6 6-.8l3.3 1.2-3 19.1H9.2l-3-19.1 3.3-1.2zM32 32H8c-.4 0-.7.3-.7.7s.3.7.7.7h24c.4 0 .7-.3.7-.7s-.3-.7-.7-.7zm0 2.7H8c-.4 0-.7.3-.7.7s.3.6.7.6h24c.4 0 .7-.3.7-.7s-.3-.6-.7-.6zm-17.9-8.9c-1 0-1.8-.3-2.4-.6l.1-2.1c.6.4 1.4.6 2 .6.8 0 1.2-.4 1.2-1.3s-.4-1.3-1.3-1.3h-1.3l.2-1.9h1.1c.6 0 1-.3 1-1.3 0-.8-.4-1.2-1.1-1.2s-1.2.2-1.9.4l-.2-1.9c.7-.4 1.5-.6 2.3-.6 2 0 3 1.3 3 2.9 0 1.2-.4 1.9-1.1 2.3 1 .4 1.3 1.4 1.3 2.5.3 1.8-.6 3.5-2.9 3.5zm4-5.5c0-3.9 1.2-5.5 3.2-5.5s3.2 1.6 3.2 5.5-1.2 5.5-3.2 5.5-3.2-1.6-3.2-5.5zm4.1 0c0-2-.1-3.5-.9-3.5s-1 1.5-1 3.5.1 3.5 1 3.5c.8 0 .9-1.5.9-3.5zm4.5-1.4c-.9 0-1.5-.8-1.5-2.1s.6-2.1 1.5-2.1 1.5.8 1.5 2.1-.5 2.1-1.5 2.1zm0-.8c.4 0 .7-.5.7-1.2s-.2-1.2-.7-1.2-.7.5-.7 1.2.3 1.2.7 1.2z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M36.7 31.1l-2.8-1.3-4.7-9.1 7.5-3.5c.4-.2.6-.6.4-1s-.6-.5-1-.4l-7.5 3.5-7.8-15c-.3-.5-1.1-.5-1.4 0l-7.8 15L4 15.9c-.4-.2-.8 0-1 .4s0 .8.4 1l7.5 3.5-4.7 9.1-2.8 1.3c-.4.2-.6.6-.4 1 .1.3.4.4.7.4.1 0 .2 0 .3-.1l1-.4-1.5 2.8c-.1.2-.1.5 0 .8.1.2.4.3.7.3h31.7c.3 0 .5-.1.7-.4.1-.2.1-.5 0-.8L35.1 32l1 .4c.1 0 .2.1.3.1.3 0 .6-.2.7-.4.1-.3 0-.8-.4-1zm-5.1-2.3l-9.8-4.6 6-2.8 3.8 7.4zM20 6.4L27.1 20 20 23.3 12.9 20 20 6.4zm-7.8 15l6 2.8-9.8 4.6 3.8-7.4zm22.4 13.1H5.4L7.2 31 20 25l12.8 6 1.8 3.5z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M5.9 5.9v28.2h28.2V5.9H5.9zM19.1 20l-8.3 8.3c-2-2.2-3.2-5.1-3.2-8.3s1.2-6.1 3.2-8.3l8.3 8.3zm-7.4-9.3c2.2-2 5.1-3.2 8.3-3.2s6.1 1.2 8.3 3.2L20 19.1l-8.3-8.4zM20 20.9l8.3 8.3c-2.2 2-5.1 3.2-8.3 3.2s-6.1-1.2-8.3-3.2l8.3-8.3zm.9-.9l8.3-8.3c2 2.2 3.2 5.1 3.2 8.3s-1.2 6.1-3.2 8.3L20.9 20zm8.4-10.2c-1.2-1.1-2.6-2-4.1-2.6h6.6l-2.5 2.6zm-18.6 0L8.2 7.2h6.6c-1.5.6-2.9 1.5-4.1 2.6zm-.9.9c-1.1 1.2-2 2.6-2.6 4.1V8.2l2.6 2.5zM7.2 25.2c.6 1.5 1.5 2.9 2.6 4.1l-2.6 2.6v-6.7zm3.5 5c1.2 1.1 2.6 2 4.1 2.6H8.2l2.5-2.6zm18.6 0l2.6 2.6h-6.6c1.4-.6 2.8-1.5 4-2.6zm.9-.9c1.1-1.2 2-2.6 2.6-4.1v6.6l-2.6-2.5zm2.6-14.5c-.6-1.5-1.5-2.9-2.6-4.1l2.6-2.6v6.7z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M35.1 33.6L33.2 6.2c0-.4-.3-.7-.7-.7H13.9c-.4 0-.7.3-.7.7s.3.7.7.7h18l.7 10.5H20.8c-8.8.2-15.9 7.5-15.9 16.4 0 .4.3.7.7.7h28.9c.2 0 .4-.1.5-.2s.2-.3.2-.5v-.2h-.1zm-28.8-.5C6.7 25.3 13 19 20.8 18.9h11.9l1 14.2H6.3zm11.2-6.8c0 1.2-1 2.1-2.1 2.1s-2.1-1-2.1-2.1 1-2.1 2.1-2.1 2.1 1 2.1 2.1zm6.3 0c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1s1-2.1 2.1-2.1 2.1 1 2.1 2.1z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M20 33.8c7.6 0 13.8-6.2 13.8-13.8S27.6 6.2 20 6.2 6.2 12.4 6.2 20 12.4 33.8 20 33.8zm0-26.3c6.9 0 12.5 5.6 12.5 12.5S26.9 32.5 20 32.5 7.5 26.9 7.5 20 13.1 7.5 20 7.5zm-.4 15h.5c1.8 0 3-1.1 3-3.7 0-2.2-1.1-3.6-3.1-3.6h-2.6v10.6h2.2v-3.3zm0-5.2h.4c.6 0 .9.5.9 1.7 0 1.1-.3 1.7-.9 1.7h-.4v-3.4z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M30.2 29.3c2.2-2.5 3.6-5.7 3.6-9.3s-1.4-6.8-3.6-9.3l3.6-3.6c.3-.3.3-.7 0-.9-.3-.3-.7-.3-.9 0l-3.6 3.6c-2.5-2.2-5.7-3.6-9.3-3.6s-6.8 1.4-9.3 3.6L7.1 6.2c-.3-.3-.7-.3-.9 0-.3.3-.3.7 0 .9l3.6 3.6c-2.2 2.5-3.6 5.7-3.6 9.3s1.4 6.8 3.6 9.3l-3.6 3.6c-.3.3-.3.7 0 .9.1.1.3.2.5.2s.3-.1.5-.2l3.6-3.6c2.5 2.2 5.7 3.6 9.3 3.6s6.8-1.4 9.3-3.6l3.6 3.6c.1.1.3.2.5.2s.3-.1.5-.2c.3-.3.3-.7 0-.9l-3.8-3.6z"></path>
                                            </svg>
                                        </li>
                                        <li class="l_custom_svg">
                                            <svg viewBox="0 0 40 40">
                                                <path fill="currentColor" d="M34.1 34.1H5.9V5.9h28.2v28.2zM7.2 32.8h25.6V7.2H7.2v25.6zm13.5-18.3a.68.68 0 0 0-.7-.7.68.68 0 0 0-.7.7v10.9a.68.68 0 0 0 .7.7.68.68 0 0 0 .7-.7V14.5z"></path>
                                            </svg>
                                        </li>
                                    </ul>
                                    <p class="tc cb">LT01: 70% wool, 15% polyester, 10% polyamide, 5% acrylic 900 Grms/mt</p>
                                </div>
                            </div>
                            <div v-if="false" class="panel entry-content sp-tab des_mb_2 des_style_1 dn" id="tab_reviews_product">
                                <div class="js_ck_view"></div>
                                <div class="heading bgbl dn"><a class="tab-heading flex al_center fl_between pr cd chp fwm" href="#tab_reviews_product"><span class="txt_h_tab">评论</span><span class="nav_link_icon ml__5"></span></a></div>
                                <div class="sp-tab-content">
                                    <div class="lt-block-reviews">
                                        <div class="r--desktop r--tablet w__100">
                                            <div id="r--masonry-theme" class="r--show-part-preview">
                                                <div class="r--masonry-theme">
                                                    <div class="header-v1 masonry-header">
                                                        <div class="r--header">
                                                            <div class="r--overview">
                                                                <div class="r--overview-left">
                                                                    <div class="r--star-block r--star-850"><span class="r--title-average">Average</span><span class="r--stars_average">4.8</span>
                                                                        <div class="r--stars cpl">
                                                                            <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span></span></div>
                                                                            <span class="r--total-view">13 <span>reviews</span></span></div>
                                                                    </div>
                                                                    <table class="r--rateList r--rate-850">
                                                                        <tr class="">
                                                                            <td class="r--rate-name"><div>Excellent</div></td>
                                                                            <td class="r--rate-numeral"><span class="r--total-bar-default"><span class="r--bar_bak_gray width__93"><span class="r--bar-active"><span class="r--rate-percent-default">12 <span class="r--rate-after"></span><span class="r--rate-before"></span></span></span></span></span></td>
                                                                        </tr>
                                                                        <tr class="r--disable">
                                                                            <td class="r--rate-name"><div>Very Good</div></td>
                                                                            <td class="r--rate-numeral"><span class="r--total-bar-default"><span class="r--bar_bak_gray"><span class="r--bar-active r--noneBack"><span class="r--rate-percent-default">0 <span class="r--rate-after"></span><span class="r--rate-before"></span></span></span></span></span></td>
                                                                        </tr>
                                                                        <tr class="">
                                                                            <td class="r--rate-name"><div>Average</div></td>
                                                                            <td class="r--rate-numeral"><span class="r--total-bar-default"><span class="r--bar_bak_gray width__7"><span class="r--bar-active"><span class="r--rate-percent-default">1 <span class="r--rate-after"></span><span class="r--rate-before"></span></span></span></span></span></td>
                                                                        </tr>
                                                                        <tr class="r--disable">
                                                                            <td class="r--rate-name"><div>Poor</div></td>
                                                                            <td class="r--rate-numeral"><span class="r--total-bar-default"><span class="r--bar_bak_gray"><span class="r--bar-active r--noneBack"><span class="r--rate-percent-default">0 <span class="r--rate-after"></span><span class="r--rate-before"></span></span></span></span></span></td>
                                                                        </tr>
                                                                        <tr class="r--disable">
                                                                            <td class="r--rate-name"><div>Terrible</div></td>
                                                                            <td class="r--rate-numeral"><span class="r--total-bar-default"><span class="r--bar_bak_gray"><span class="r--bar-active r--noneBack"><span class="r--rate-percent-default">0 <span class="r--rate-after"></span><span class="r--rate-before"></span></span></span></span></span></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div class="r--overview-right">
                                                                    <div class="show-modal-mobile"><a class="r--button r--flex-center bg-yellow text-white ajax_pp_js" href="#" data-id="#popup-leave-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.37" height="17.8" viewBox="0 0 21.682 21.602">
                                                                            <g id="Symbol_32_2" data-name="Symbol 32 – 2" transform="translate(-961.98 -374.155)">
                                                                                <path d="M0,0H4V11.2L1.937,14h0L0,11.2Z" transform="translate(979.891 381.756) rotate(40)" fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-width="1"></path>
                                                                                <path d="M0,0H4" transform="translate(972.692 390.335) rotate(40)" fill="none" stroke="#ffffff" stroke-width="1"></path>
                                                                                <g transform="translate(981.126 380.964) rotate(40)" fill="none" stroke="#ffffff" stroke-width="1">
                                                                                    <rect width="3.128" height="1.4" stroke="none"></rect>
                                                                                    <rect x="0.5" y="0.5" width="2.128" height="0.4" fill="none"></rect>
                                                                                </g>
                                                                                <path d="M2858.324,3384.6h7.412" transform="translate(-1891.1 -3003.987)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
                                                                                <path d="M2858.324,3384.6h7.412" transform="translate(-1891.1 -2999.611)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-width="1"></path>
                                                                                <path d="M8.952,0H15a2,2,0,0,1,2,2V15a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V12.162" transform="translate(979.48 391.655) rotate(180)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
                                                                            </g>
                                                                        </svg>
                                                                        <span class="r--text-write">Write a review</span></a></div>
                                                                </div>
                                                            </div>
                                                            <div class="r--filter-review">
                                                                <div class="r--filter-wrapper">
                                                                    <div class="r--sortBy">
                                                                        <div class="r--unset-select r--sort-button r--filter-link r--flex-center el-popover__reference"><span class="r--select">Sort by: Latest </span><img src="/images/single-product/icon-down.svg" width="8" height="4" class="r--select r--icon-down" alt=""></div>
                                                                    </div>
                                                                    <div class="r--filter">
                                                                        <div class="r--unset-select r--sort-button r--filter-link r--flex-center el-popover__reference"><span class="r--select">Filter</span><img src="/images/single-product/icon-down.svg" width="8" height="4" class="r--select r--icon-down" alt=""></div>
                                                                    </div>
                                                                </div>
                                                                <div class="r--showing r--f-left"><span class="r--text-showing">Showing 1 - 6 of 13 reviews</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="r--grid">
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white">P</div>
                                                                <span class="r--author-review">Peter</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white avatar--bg-red">K</div>
                                                                <span class="r--author-review">Kodeman</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white avatar--bg-purple">G</div>
                                                                <span class="r--author-review">Glager</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white  avatar--bg-blue">C</div>
                                                                <span class="r--author-review">Chaos</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white">S</div>
                                                                <span class="r--author-review">Sevenor</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="r--grid-item">
                                                            <div class="r--author r--text-limit">
                                                                <div class="r--avatar-default text-center text-white avatar--bg-purple">D</div>
                                                                <span class="r--author-review">Dranking</span></div>
                                                            <div class="r--item-body">
                                                                <div class="r--item-body-top">
                                                                    <div class="r--stars-author r--star-head r--body-item r--flex-center">
                                                                        <div class="kalles-rating-result"><span class="kalles-rating-result__pipe"><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big"></span><span class="kalles-rating-result__start kalles-rating-result__start--big active"></span></span></div>
                                                                    </div>
                                                                </div>
                                                                <p class="r--title-review r--body-item">Contrary to popular belief</p>
                                                                <p class="r--content-review r--body-item">It is a long established fact that a reader will be distracted by the readable content of a page </p>
                                                                <time datetime="2020-01-28T17:29:54Z" class="r--date-review r--top r--text-limit">15 days ago</time>
                                                                <ul class="r--reply-helpul r--body-item r--flex-center">
                                                                    <li class="r--helpul-item">
                                                                        <div class="r--like">
                                                                            <div class="r--like-icon like r--flex-center">
                                                                                <svg width="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.939" class="r--icon-like">
                                                                                    <g transform="translate(-926.048 -414.43)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(929.463 415.021)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.769v11.039l-2.9-.279V422.22Z" transform="translate(-16.112 -0.939)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">12</span></div>
                                                                            <div class="r--like-icon dislike r--flex-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.762 17.659" class="r--icon-dislike">
                                                                                    <g transform="translate(956.922 435.325) rotate(180)">
                                                                                        <path d="M0,14.842V6.033l.266.709S3.779,4.692,3.9,1.674s2.5-1.661,2.624.45-.095,2.192.517,3.909c1.392-.021,2.211-.013,2.59-.006H9.71q.06,0,.119,0l.056,0v0A1.532,1.532,0,0,1,10.923,8.5a1.533,1.533,0,0,1,.45,2.515,1.533,1.533,0,0,1-.387,2.485,1.859,1.859,0,0,1,.257.966c0,.847-.515,1.584-1.15,1.584L9.237,16c-.7.042-2.286.125-2.717.131H6.448A31.646,31.646,0,0,1,0,14.842Z" transform="translate(944.575 418.257)" class="cls-1"></path>
                                                                                        <path d="M945.558,421.946v10.321l-2.9.261V421.777Z" transform="translate(-1 2.296)" class="cls-2"></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span class="r--like-count like_0">08</span></div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="r--helpul-item r--reply-review r--flex-center ajax_pp_js" data-id="#popup-reply-review">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.125 17.584" class="r--icon-reply">
                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect width="14.094" height="3.924" class="cls-1"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g transform="translate(-784.909 -833.715)">
                                                                                <path d="M4.01,12.938H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18.125a2,2,0,0,1,2,2v8.937a2,2,0,0,1-2,2H8.78l-.667.923L6.4,16.232Z" transform="translate(785.409 834.215)" class="cls-2"></path>
                                                                                <g transform="translate(788.554 839.127)" class="cls-3">
                                                                                    <g transform="translate(-739 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-734 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g transform="translate(-729 -839)">
                                                                                        <g transform="translate(739 839)" class="cls-4">
                                                                                            <circle cx="1.75" cy="1.75" r="1.75" class="cls-5"></circle>
                                                                                            <circle cx="1.75" cy="1.75" r="1.25" class="cls-1"></circle>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span>02</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="r--load-more"><a href="#" class="r--text-load-more">Load more</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end tab contents -->
                        </div>
                    </div>
                </div>
                <!-- end description and review -->

                <div class="clearfix"></div>

                <ProductRecommendationsSection v-if="false" />

                <ProductRecentlyViewedSection/>

            </div>
        </div>

        <Footer/>

    </div>

    <!-- add-to-cart sticky bar -->
    <div v-if="product" id="kalles-section-sticky_atc" class="kalles-section">
        <div class="sticky_atc_wrap mobile_true pf b__0 l__0 r__0 pt__10 pb__10 bgw z_100 sticky_atc_shown">
            <div class="container">
                <div class="row al_center fl_center">
                    <div class="col sticky_atc_content">
                        <div class="row no-gutters al_center">
                            <div class="col-auto sticky_atc_thumb mr__10 flex al_center"><img :alt="product.title" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="options.sku ? options.sku.url_img : product.scene_url" class="lazyload w__100 lz_op_ef" /></div>
                            <div class="col sticky_atc_info">
                                <h4 class="fs__14 mg__0">{{product.title}}</h4>
                                <span class="txt_under sticky_atc_a cp fwm"><template v-for="(dt, index) in options.spec_list" :key="index"><template v-if="dt"><template v-if="index != 0"> / </template>{{dt.title}}</template></template></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto sticky_atc_btn variations_form flex wrap al_center fl_center">
                        <div class="txt_under sticky_atc_a cp fwm dn tc truncate mr__10"><template v-for="(dt, index) in options.spec_list" :key="index"><template v-if="dt"><template v-if="index != 0"> / </template>{{dt.title}}</template></template></div>
                        <div v-if="website.show_price" class="sticky_atc_price">
                            <span style="font-size: 12px;">¥</span>
                            <template v-if="options.sku">{{options.sku.price}}</template>
                            <template v-else>{{product.min_price}}<template v-if="product.min_price != product.max_price">-{{product.max_price}}</template></template>
                        </div>
                        <div class="quantity pr ml__10 mr__10">
                            <input type="number" class="input-text qty text tc js_sticky_qty qty_cart_js" min="0" max="9999" name="quantity" value="1">
                            <div class="qty tc fs__14"><a class="plus db cb pa pr__15 tr r__0" href="#"><i class="facl facl-plus"></i></a><a class="minus db cb pa pl__15 tl l__0" href="#"><i class="facl facl-minus"></i></a></div>
                        </div>
                        <button data-time="6000" data-ani="none" class="single_add_to_cart_button button truncate sticky_atc_js"><span class="txt_add">在线购买</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end add-to-cart sticky bar -->

    <!-- popup ask a question-->
    <div id="popup-ask-a-question" class="d-none">
        <div class="mfp-with-anim white-popup ajax_pp_popup ask_ajax_pp_js cb">
            <form method="post" action="#" class="contact-form">
                <h3 class="tc mg__0 mb__20">问题咨询</h3>
                <p>
                    <label for="ContactFormAsk-name">姓名</label>
                    <input type="text" id="ContactFormAsk-name" name="contact[name]" required="required" value=""/>
                </p>
                <p>
                    <label for="ContactFormAsk-name">联系方式</label>
                    <input type="email" id="ContactFormAsk-email" name="email" value="" required="required"/>
                </p>
                <p class="hide hidden">
                    <label for="ContactFormAsk-phone">Your Phone Number</label>
                    <input type="tel" id="ContactFormAsk-phone" name="contact[phone]" pattern="[0-9\-]*" value=""/>
                </p>
                <p class="hide hidden">
                    <label for="ContactFormAsk-product">产品编号</label>
                    <textarea rows="20" id="ContactFormAsk-product" name="contact[product]" required="required"></textarea>
                </p>
                <p>
                    <label for="ContactFormAsk-message">您的留言</label>
                    <textarea rows="20" id="ContactFormAsk-message" name="contact[body]" required="required"></textarea>
                </p>
                <input type="submit" class="button w__100" value="提交"/>
            </form>
            <button title="关闭 (Esc)" type="button" class="mfp-close">×</button>
        </div>
    </div>
    <!-- end popup ask a question-->

    <!-- popup delivery and return-->
    <div id="popup-delivery-and-return" class="d-none">
        <div class="mfp-with-anim white-popup ajax_pp_popup dl_ajax_pp_js cb">
            <h5 class="mt__0 mb__15">Delivery</h5>
            <ul>
                <li>All orders shipped with UPS Express.</li>
                <li>Always free shipping for orders over US $250.</li>
                <li>All orders are shipped with a UPS tracking number.</li>
            </ul>
            <h5 class="mt__25 mb__15">Returns</h5>
            <ul>
                <li>Items returned within 14 days of their original shipment date in same as new condition will be eligible for a full refund or store credit.</li>
                <li>Refunds will be charged back to the original form of payment used for purchase.</li>
                <li>Customer is responsible for shipping charges when making returns and shipping/handling fees of original purchase is non-refundable.</li>
                <li>All sale items are final purchases.</li>
            </ul>
            <h5 class="mt__25 mb__15">Help</h5>
            <ul>
                <li>Give us a shout if you have any other questions and/or concerns.</li>
                <li>Email: <a href="mailto:contact@domain.com"><span class="__cf_email__">contact@domain.com</span></a></li>
                <li>Phone: +1 (23) 456 789</li>
            </ul>
            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
        </div>
    </div>
    <!-- end popup delivery and return-->

    <!-- popup size guide-->
    <div id="popup-size-guide" class="d-none">
        <div class="mfp-with-anim white-popup ajax_pp_popup ps_ajax_pp_js cb">
            <div class="first-column">
                <h3>Sizing &amp; Fit Guide</h3>
                <p>Please be aware that most of our clothing is Juniors' sizing, which runs smaller than regular US / European Women sizing. Refer to the measurements chart for each individual product to determine the best size for you. Product specific size chart and information can be found in product description or in product picture gallery.</p>
                <p>Our team does it's best to include accurate size details and measurement of each item in the product descriptions. If you have questions regarding sizing or any other information on a specific item, please email us at hello@kalles.com for specific details.</p>
            </div>
            <div class="second-column">
                <h3>Find the best size</h3>
                <p>When comparing your measurements to those listed on the site, make sure to check if the fabric is stretchy or not. If it is, it can stretch an inch or more.</p>
                <ul>
                    <li><strong> Bust:</strong> Wrap the measuring tape loosely around the fullest part of your bust to ensure a comfortable fit. </li>
                    <li><strong>Waist:</strong> Measure the narrowest part of your natural waist, holding the measuring tape loose enough to fit comfortably. </li>
                    <li><strong>Hips:</strong> Drape the measuring tape around the widest part of your hips, resting the tape comfortably against your body. </li>
                    <li><strong>Length:</strong> For tops and dresses, measure from the top of your shoulder to the hem. For strapless dresses, measure from the highest part of the neckline to the hem. For pants and skirts, measure from the top of the waist to the hem. </li>
                    <li><strong>Sleeve:</strong> With your arm outstretched, measure from the base of your neck to the end of the sleeve hem to determine where the sleeve length will fall on you. </li>
                </ul>
            </div>
            <div class="clearfix"></div>
            <hr class="hr">
            <h3 class="text-center">kalles Size Chart</h3>
            <br>
            <div class="tabset">
                <!-- Tab 1 -->
                <input type="radio" name="tabset" id="tab1" aria-controls="size-chart-tab-content__dresses" checked="">
                <label for="tab1">Dresses</label>
                <!-- Tab 2 -->
                <input type="radio" name="tabset" id="tab2" aria-controls="size-chart-tab-content__tops">
                <label for="tab2">Tops</label>
                <!-- Tab 3 -->
                <input type="radio" name="tabset" id="tab3" aria-controls="size-chart-tab-content__bottoms">
                <label for="tab3">Bottoms</label>
                <!-- Tab 4 -->
                <input type="radio" name="tabset" id="tab4" aria-controls="size-chart-tab-content__denim">
                <label for="tab4">Denim</label>
                <!-- Tab 5 -->
                <input type="radio" name="tabset" id="tab5" aria-controls="size-chart-tab-content__swimwear">
                <label for="tab5">Swimwear</label>
                <!-- Tab 6 -->
                <input type="radio" name="tabset" id="tab6" aria-controls="size-chart-tab-content__shoes">
                <label for="tab6">Shoes</label>
                <div class="tab-panels">
                    <section id="size-chart-tab-content__dresses" class="tab-panel">
                        <div class="table-responsive mb__30">
                            <h4 class="mb__20">Dress Size Chart (Inch)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><strong>DRESSES</strong><br>
                                        SIZE IN INCHES </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">31"-32"</td>
                                    <td class="text-center">24"-25"</td>
                                    <td class="text-center">34"-35"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">33"-34"</td>
                                    <td class="text-center bg-row-bold">26"-27"</td>
                                    <td class="text-center bg-row-bold">36"-37"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">35"-36"</td>
                                    <td class="text-center">28"-29"</td>
                                    <td class="text-center">38"-39"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">37"-39"</td>
                                    <td class="text-center bg-row-bold">30"-32"</td>
                                    <td class="text-center bg-row-bold">40"-42"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">40"-42"</td>
                                    <td class="text-center">33"-35"</td>
                                    <td class="text-center">43"-45"</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <h4 class="mb__20">Dress Size Chart (cm)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><strong>DRESSES</strong><br>
                                        SIZE IN CM </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">78.7-81.2</td>
                                    <td class="text-center">60.9-63.5</td>
                                    <td class="text-center">86.3-88.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">83.8-86.3</td>
                                    <td class="text-center bg-row-bold">66-68.5</td>
                                    <td class="text-center bg-row-bold">91.4-93.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">88.9-91.4</td>
                                    <td class="text-center">71.1-73.6</td>
                                    <td class="text-center">96.5-99</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">93.9-99</td>
                                    <td class="text-center bg-row-bold">76.2-81.2</td>
                                    <td class="text-center bg-row-bold">101.6-106.6</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">101.6-106.6</td>
                                    <td class="text-center">81.2-88.9</td>
                                    <td class="text-center">109.2-114.3</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="size-chart-tab-content__tops" class="tab-panel">
                        <div class="table-responsive mb__30">
                            <h4 class="mb__20">Tops Size Chart (Inch)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="4" class="text-center"><strong>TOPS</strong><br>
                                        SIZE IN INCHES </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">31"-32"</td>
                                    <td class="text-center">24"-25"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">33"-34"</td>
                                    <td class="text-center bg-row-bold">26"-27"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">35"-36"</td>
                                    <td class="text-center">28"-29"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">37"-39"</td>
                                    <td class="text-center bg-row-bold">30"-32"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">40"-42"</td>
                                    <td class="text-center">33"-35"</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <h4 class="mb__20">Tops Size Chart (cm)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="4" class="text-center"><strong>TOPS</strong><br>
                                        SIZE IN CM </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">78.7-81.2</td>
                                    <td class="text-center">60.9-63.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">83.8-86.3</td>
                                    <td class="text-center bg-row-bold">66-68.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">88.9-91.4</td>
                                    <td class="text-center">71.1-73.6</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">93.9-99</td>
                                    <td class="text-center bg-row-bold">76.2-81.2</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">101.6-106.6</td>
                                    <td class="text-center">83.8-88.9</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="size-chart-tab-content__bottoms" class="tab-panel">
                        <div class="table-responsive mb__30">
                            <h4 class="mb__20">Bottoms Size Chart (Inch)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="4" class="text-center"><strong>BOTTOMS</strong><br>
                                        SIZE IN INCHES </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">24"-25"</td>
                                    <td class="text-center">34"-35"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">26"-27"</td>
                                    <td class="text-center bg-row-bold">36"-37"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">28"-29"</td>
                                    <td class="text-center">38"-39"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">30"-32"</td>
                                    <td class="text-center bg-row-bold">40"-42"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">33"-35"</td>
                                    <td class="text-center">43"-45"</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <h4 class="mb__20">Bottoms Size Chart (cm)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="4" class="text-center"><strong>BOTTOMS</strong><br>
                                        SIZE IN CM </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">60.9-63.5</td>
                                    <td class="text-center">86.3-88.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2-4</td>
                                    <td class="text-center bg-row-bold">66-68.5</td>
                                    <td class="text-center bg-row-bold">91.4-93.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">6-8</td>
                                    <td class="text-center">71.1-73.6</td>
                                    <td class="text-center">96.4-93.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">10-12</td>
                                    <td class="text-center bg-row-bold">76.2-81.2</td>
                                    <td class="text-center bg-row-bold">101.6-106.6</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">83.8-88.9</td>
                                    <td class="text-center">109.2-114.3</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="size-chart-tab-content__denim" class="tab-panel">
                        <div class="table-responsive mb__30">
                            <h4 class="mb__20">Denim Size Chart (Inch)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><b>DENIM</b><br>
                                        SIZE IN INCHES </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">WAIST SIZE</td>
                                    <td class="text-center bg-row-head">JUNIOR SIZING</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">LOW WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">24</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">24"-25"</td>
                                    <td class="text-center">26"-27"</td>
                                    <td class="text-center">32"-33"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">25</td>
                                    <td class="text-center bg-row-bold">1</td>
                                    <td class="text-center bg-row-bold">25"-26"</td>
                                    <td class="text-center bg-row-bold">28"-29"</td>
                                    <td class="text-center bg-row-bold">33"-34"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">26</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">26"-27"</td>
                                    <td class="text-center">30"-31"</td>
                                    <td class="text-center">34"-35"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">27</td>
                                    <td class="text-center bg-row-bold">5</td>
                                    <td class="text-center bg-row-bold">27"-28"</td>
                                    <td class="text-center bg-row-bold">31"-32"</td>
                                    <td class="text-center bg-row-bold">35"-36"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">28</td>
                                    <td class="text-center">7</td>
                                    <td class="text-center">28"-29"</td>
                                    <td class="text-center">32"-33"</td>
                                    <td class="text-center">36"-37"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">29</td>
                                    <td class="text-center bg-row-bold">9</td>
                                    <td class="text-center bg-row-bold">29"-30"</td>
                                    <td class="text-center bg-row-bold">33"-34"</td>
                                    <td class="text-center bg-row-bold">37"-38"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">30</td>
                                    <td class="text-center">11</td>
                                    <td class="text-center">30"-31"</td>
                                    <td class="text-center">34"-35"</td>
                                    <td class="text-center">38"-39"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">31</td>
                                    <td class="text-center bg-row-bold">13</td>
                                    <td class="text-center bg-row-bold">31"-3"</td>
                                    <td class="text-center bg-row-bold">35"-36"</td>
                                    <td class="text-center bg-row-bold">39"-40"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">32</td>
                                    <td class="text-center">15</td>
                                    <td class="text-center">32"-33"</td>
                                    <td class="text-center">36"-37"</td>
                                    <td class="text-center">40"-41"</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <h4 class="mb__20">Denim Size Chart (cm)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><strong>DENIM</strong><br>
                                        SIZE IN CM </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">WAIST SIZE</td>
                                    <td class="text-center bg-row-head">JUNIOR SIZING</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">LOW WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">24</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">60.9-63.5</td>
                                    <td class="text-center">66-68.5</td>
                                    <td class="text-center">81.2-83.8</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">25</td>
                                    <td class="text-center bg-row-bold">1</td>
                                    <td class="text-center bg-row-bold">63.5-66</td>
                                    <td class="text-center bg-row-bold">71.1-73.6</td>
                                    <td class="text-center bg-row-bold">83.8-86.3</td>
                                </tr>
                                <tr>
                                    <td class="text-center">26</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">66-68.5</td>
                                    <td class="text-center">76.2-78.7</td>
                                    <td class="text-center">86.3-88.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">27</td>
                                    <td class="text-center bg-row-bold">5</td>
                                    <td class="text-center bg-row-bold">68.5-71.1</td>
                                    <td class="text-center bg-row-bold">78.7-81.2</td>
                                    <td class="text-center bg-row-bold">88.9-91.4</td>
                                </tr>
                                <tr>
                                    <td class="text-center">28</td>
                                    <td class="text-center">7</td>
                                    <td class="text-center">71.1-73.6</td>
                                    <td class="text-center">81.2-83.8</td>
                                    <td class="text-center">91.4-93.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">29</td>
                                    <td class="text-center bg-row-bold">9</td>
                                    <td class="text-center bg-row-bold">73.6-76.2</td>
                                    <td class="text-center bg-row-bold">83.8-86.3</td>
                                    <td class="text-center bg-row-bold">93.9-96.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">30</td>
                                    <td class="text-center">11</td>
                                    <td class="text-center">76.2-78.7</td>
                                    <td class="text-center">86.3-88.9</td>
                                    <td class="text-center">96.5-99</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">31</td>
                                    <td class="text-center bg-row-bold">13</td>
                                    <td class="text-center bg-row-bold">78.7-81.2</td>
                                    <td class="text-center bg-row-bold">88.9-91.4</td>
                                    <td class="text-center bg-row-bold">99-101.6</td>
                                </tr>
                                <tr>
                                    <td class="text-center">32</td>
                                    <td class="text-center">15</td>
                                    <td class="text-center">81.2-83.8</td>
                                    <td class="text-center">91.4-93.9</td>
                                    <td class="text-center">101.6-104.1</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="size-chart-tab-content__swimwear" class="tab-panel">
                        <div class="table-responsive mb__30">
                            <h4 class="mb__20">Swimwear Size Chart (Inch)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><strong>SWIMWEAR</strong><br>
                                        SIZE IN INCHES </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">31"-32"</td>
                                    <td class="text-center">24"-25"</td>
                                    <td class="text-center">33"-34"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2</td>
                                    <td class="text-center bg-row-bold">33"-34"</td>
                                    <td class="text-center bg-row-bold">26"-27"</td>
                                    <td class="text-center bg-row-bold">35"-37"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">4</td>
                                    <td class="text-center">35"-36"</td>
                                    <td class="text-center">28"-29"</td>
                                    <td class="text-center">37"-38"</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">6</td>
                                    <td class="text-center bg-row-bold">37"-39"</td>
                                    <td class="text-center bg-row-bold">30"-32"</td>
                                    <td class="text-center bg-row-bold">39"-41"</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">40"-42"</td>
                                    <td class="text-center">33"-35"</td>
                                    <td class="text-center">42"-44"</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <h4 class="mb__20">Swimwear Size Chart (cm)</h4>
                            <table class="sizeguide_table w__100 m-0">
                                <tbody>
                                <tr>
                                    <td colspan="5" class="text-center"><strong>SWIMWEAR</strong><br>
                                        SIZE IN CM </td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-head">SIZE</td>
                                    <td class="text-center bg-row-head">US / CAN</td>
                                    <td class="text-center bg-row-head">BUST</td>
                                    <td class="text-center bg-row-head">WAIST</td>
                                    <td class="text-center bg-row-head">HIPS</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XS</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">78.7-81.2</td>
                                    <td class="text-center">60.9-63.5</td>
                                    <td class="text-center">83.8-86.3</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">S</td>
                                    <td class="text-center bg-row-bold">2</td>
                                    <td class="text-center bg-row-bold">83.8-86.3</td>
                                    <td class="text-center bg-row-bold">66-68.5</td>
                                    <td class="text-center bg-row-bold">88.9-93.9</td>
                                </tr>
                                <tr>
                                    <td class="text-center">M</td>
                                    <td class="text-center">4</td>
                                    <td class="text-center">88.9-91.4</td>
                                    <td class="text-center">71.1-73.6</td>
                                    <td class="text-center">93.9-96.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">L</td>
                                    <td class="text-center bg-row-bold">6</td>
                                    <td class="text-center bg-row-bold">93.9-99</td>
                                    <td class="text-center bg-row-bold">76.2-81.2</td>
                                    <td class="text-center bg-row-bold">99-104.1</td>
                                </tr>
                                <tr>
                                    <td class="text-center">XL</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">101.6-106.6</td>
                                    <td class="text-center">81.2-88.9</td>
                                    <td class="text-center">106.6-111.7</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="size-chart-tab-content__shoes" class="tab-panel">
                        <div class="table-responsive">
                            <h4 class="mb__20">Shoes Size Chart</h4>
                            <table class="sizeguide_table w__100 ml-auto mr-auto">
                                <tbody>
                                <tr>
                                    <td class="text-center bg-row-head">US</td>
                                    <td class="text-center bg-row-head">EURO</td>
                                    <td class="text-center bg-row-head">UK</td>
                                    <td class="text-center bg-row-head">AUS</td>
                                    <td class="text-center bg-row-head">JAPAN</td>
                                    <td class="text-center bg-row-head">CHINA</td>
                                </tr>
                                <tr>
                                    <td class="text-center">5</td>
                                    <td class="text-center">35-36</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">3.5</td>
                                    <td class="text-center">21.5</td>
                                    <td class="text-center">35</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">5.5</td>
                                    <td class="text-center bg-row-bold">36</td>
                                    <td class="text-center bg-row-bold">3.5</td>
                                    <td class="text-center bg-row-bold">4</td>
                                    <td class="text-center bg-row-bold">22</td>
                                    <td class="text-center bg-row-bold">35.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">6</td>
                                    <td class="text-center">36-37</td>
                                    <td class="text-center">4</td>
                                    <td class="text-center">4.5</td>
                                    <td class="text-center">22.5</td>
                                    <td class="text-center">36</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">6.5</td>
                                    <td class="text-center bg-row-bold">37</td>
                                    <td class="text-center bg-row-bold">4.5</td>
                                    <td class="text-center bg-row-bold">5</td>
                                    <td class="text-center bg-row-bold">23</td>
                                    <td class="text-center bg-row-bold">36.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">7</td>
                                    <td class="text-center">37-38</td>
                                    <td class="text-center">5</td>
                                    <td class="text-center">5.5</td>
                                    <td class="text-center">23.5</td>
                                    <td class="text-center">37</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">7.5</td>
                                    <td class="text-center bg-row-bold">38</td>
                                    <td class="text-center bg-row-bold">5.5</td>
                                    <td class="text-center bg-row-bold">6</td>
                                    <td class="text-center bg-row-bold">24</td>
                                    <td class="text-center bg-row-bold">37.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">8</td>
                                    <td class="text-center">38-39</td>
                                    <td class="text-center">6</td>
                                    <td class="text-center">6.5</td>
                                    <td class="text-center">24.5</td>
                                    <td class="text-center">38</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">8.5</td>
                                    <td class="text-center bg-row-bold">39</td>
                                    <td class="text-center bg-row-bold">6.5</td>
                                    <td class="text-center bg-row-bold">7</td>
                                    <td class="text-center bg-row-bold">25</td>
                                    <td class="text-center bg-row-bold">38.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">9</td>
                                    <td class="text-center">39-40</td>
                                    <td class="text-center">7</td>
                                    <td class="text-center">7.5</td>
                                    <td class="text-center">25.5</td>
                                    <td class="text-center">39</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">9.5</td>
                                    <td class="text-center bg-row-bold">40</td>
                                    <td class="text-center bg-row-bold">7.5</td>
                                    <td class="text-center bg-row-bold">8</td>
                                    <td class="text-center bg-row-bold">26</td>
                                    <td class="text-center bg-row-bold">39.5</td>
                                </tr>
                                <tr>
                                    <td class="text-center">10</td>
                                    <td class="text-center">40-41</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">8.5</td>
                                    <td class="text-center">26.5</td>
                                    <td class="text-center">40</td>
                                </tr>
                                <tr>
                                    <td class="text-center bg-row-bold">11</td>
                                    <td class="text-center bg-row-bold">41-42</td>
                                    <td class="text-center bg-row-bold">9</td>
                                    <td class="text-center bg-row-bold">9.5</td>
                                    <td class="text-center bg-row-bold">27.5</td>
                                    <td class="text-center bg-row-bold">41</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
            <p><b>Attention:</b> This guide provides general sizing information only, and fit can vary depending on style and brand. For more specific sizing information on the product, please refer to product description. </p>
            <b>NOTE:</b>
            <ul>
                <li>All kalles models are wearing size small in tops and dresses, and size 1, 3, or 5 in jeans depending on their body type.</li>
                <li>Most kalles jeans &amp; dresses have some stretch, please refer to product description for fabric details.</li>
                <li>Most kalles bottoms have an inseam of 31-34” depending on the cut and style. Sizing may vary depending on cut and style.</li>
            </ul>
            <br>
            <h4 class="text-center">STILL NEED HELP? CONTACT US ON</h4>
            <h4 class="text-center">+1 (646) 389-7242</h4>
            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
        </div>
    </div>
    <!-- end popup size guide-->

    <!-- mini cart box -->
    <div v-if="product" id="nt_cart_canvas" class="nt_fk_canvas dn">
        <div class="nt_mini_cart nt_js_cart flex column h__100 btns_cart_1">
            <div class="mini_cart_header flex fl_between al_center">
                <div class="h3 fwm tu fs__16 mg__0">选择购买平台</div>
                <i class="close_pp pegk pe-7s-close ts__03 cd"></i>
            </div>
            <div v-if="false" class="cart_threshold cart_thres_js">
                <div class="cart_thres_2">Almost there, add <span class="cr fwm mn_thres_js">$9.00</span> more to get <span class="cr fwm">FREE SHIPPING!</span></div>
            </div>
            <div class="mini_cart_wrap">
                <div class="mini_cart_content fixcl-scroll">
                    <div class="fixcl-scroll-content">
                        <div class="mini_cart_items js_cat_items lazyload">
                            <div class="mini_cart_item js_cart_item flex al_center pr oh">
                                <div class="ld_cart_bar"></div>
                                <a :href="'/product/item/' + product.product_id" class="mini_cart_img"><img class="w__100 lazyload" :data-src="product.scene_url" width="120" height="153" :alt="product.title" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTUzIiB2aWV3Qm94PSIwIDAgMTIwIDE1MyI+PC9zdmc+" /></a>
                                <div class="mini_cart_info"><a :href="'/product/item/' + product.product_id" class="mini_cart_title truncate">{{product.title}}</a>
                                    <div class="mini_cart_meta">
                                        <p v-if="options.spec_list" class="cart_meta_variant"><template v-for="(dt, index) in options.spec_list" :key="index"><template v-if="dt"><template v-if="index != 0"> / </template>{{dt.title}}</template></template></p>
                                        <p class="cart_selling_plan"></p>
                                        <div v-if="website.show_price" class="cart_meta_price price">
                                            <div class="cart_price"><span style="font-size: 12px;">¥</span> {{product.min_price}}<template v-if="product.min_price != product.max_price">-{{product.max_price}}</template></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="product.buy_link_tmall || product.buy_link_jd || product.buy_link_taobao || product.buy_link_suning || product.buy_link_pinduoduo" class="mini_cart_footer js_cart_footer">
                    <a v-if="product.buy_link_tmall" :href="product.buy_link_tmall" target="_blank" class="button btn-checkout mt__10 mb__10 js_add_ld d-inline-flex justify-content-center align-items-center text-white">天猫购买</a>
                    <a v-if="product.buy_link_jd" :href="product.buy_link_jd" target="_blank" class="button btn-checkout mt__10 mb__10 js_add_ld d-inline-flex justify-content-center align-items-center text-white">京东购买</a>
                    <a v-if="product.buy_link_taobao" :href="product.buy_link_taobao" target="_blank" class="button btn-checkout mt__10 mb__10 js_add_ld d-inline-flex justify-content-center align-items-center text-white">淘宝购买</a>
                    <a v-if="product.buy_link_suning" :href="product.buy_link_suning" target="_blank" class="button btn-checkout mt__10 mb__10 js_add_ld d-inline-flex justify-content-center align-items-center text-white">苏宁购买</a>
                    <a v-if="product.buy_link_pinduoduo" :href="product.buy_link_pinduoduo" target="_blank" class="button btn-checkout mt__10 mb__10 js_add_ld d-inline-flex justify-content-center align-items-center text-white">拼多多购买</a>
                </div>
            </div>
        </div>
    </div>
    <!-- end mini cart box-->
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ProductRecommendationsSection from './components/ProductRecommendationsSection.vue'
import ProductRecentlyViewedSection from './components/ProductRecentlyViewedSection.vue'
import {computed, getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import '/public/css/single-masonry-theme.css'
import '/public/css/single-product.css'
import {getFullProductV1Api} from "@/api/product/product";

export default {
    name: 'ProductItem',
    components: {Header, Footer, ProductRecommendationsSection, ProductRecentlyViewedSection},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');
        const clickLikeAdd = inject('clickLikeAdd');
        const my = inject('my');

        const options = reactive({
            title: '产品',
            name: proxy.$route.name,
            url: proxy.$route.path,
            spec_list: [],
            sku: computed(function () {
                if (options.spec_list.length > 0) {
                    const ids = [];
                    options.spec_list.forEach(function(val) {
                        ids.push(val.parent_id + ":" + val.product_spec_id);
                    });

                    return product.value.sku_list.find(o => o.keys_ids == ids.join(';'));
                } else {
                    return '';
                }
            }),
        });

        const product = ref();
        const apiProduct = () => {
            return new Promise(function (resolve) {
                getFullProductV1Api(proxy.$route.params.id).then(res => {
                    if (res.data.code == 0) {
                        product.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const clickSpec = (index, index2) => {
            options.spec_list[index] = product.value.spec_list[index].children[index2];


        }
        
        // 初始数据加载
        Promise.all([apiProduct()]).then(() => {
            // apiProductList().then(() => {
            //     mainNotyConfirm.close();
            // });

            const recently_viewed_product_ids = ref(localStorage.getItem('recently_viewed_product_ids'));
            if (recently_viewed_product_ids.value == null) {
                recently_viewed_product_ids.value = product.value.product_id;
            } else {
                if (recently_viewed_product_ids.value.split(',').indexOf(product.value.product_id) == -1) {
                    recently_viewed_product_ids.value = recently_viewed_product_ids.value + ',' + product.value.product_id;
                }
            }
            localStorage.setItem('recently_viewed_product_ids', recently_viewed_product_ids.value);
        });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-product-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {website, options, my, product, clickLikeAdd, clickSpec}
    },
}
</script>
