<template>
    <!--product recommendations section-->
    <div class="kalles-section tp_se_cdt">
        <div class="related product-extra mt__60 lazyload">
            <div class="container">
                <div class="wrap_title des_title_1">
                    <h3 class="section-title tc pr flex fl_center al_center fs__24 title_1"><span class="mr__10 ml__10">你可能也喜欢</span></h3>
                    <span class="dn tt_divider"><span></span><i class="dn clprfalse title_1 la-gem"></i><span></span></span><span class="section-subtitle db tc sub-title"></span></div>
                <div class="products nt_products_holder nt_slider row row_pr_1 cdt_des_1 round_cd_false nt_cover ratio_nt position_8 space_30 prev_next_0 btn_owl_1 dot_owl_1 dot_color_1 btn_vi_1 is-draggable" data-flickity='{"imagesLoaded": 0,"adaptiveHeight": 0, "contain": 1, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": true,"prevNextButtons": false,"percentPosition": 1,"pageDots": false, "autoPlay" : 0, "pauseAutoPlayOnHover" : true, "rightToLeft": false }'>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-03.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-04.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                                <div class="product-attr pa ts__03 cw op__0 tc">
                                    <p class="truncate mg__0 w__100">S, M, L</p>
                                </div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Ridley High Waist</a></h3>
                                <span class="price dib mb__5">$36.00</span></div>
                        </div>
                    </div>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><span class="tc nt_labels pa pe_none cw"><span class="onsale nt_label"><span>-40%</span></span></span><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-fashion-9/pr-s-49.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-fashion-9/pr-s-50.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                                <div class="product-attr pa ts__03 cw op__0 tc">
                                    <p class="truncate mg__0 w__100">S, M</p>
                                </div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Skin Sweatpans</a></h3>
                                <span class="price dib mb__5"><del>$75.00</del><ins>$45.00</ins></span>
                                <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid"><span data-bgset="/images/home-fashion-9/pr-s-50.jpg" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right js__pink"><span class="tt_txt">Pink</span><span class="swatch__value bg_color_pink lazyload"></span></span><span data-bgset="/images/home-fashion-9/pr-s-51.jpg" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right js__cyan"><span class="tt_txt">Cyan</span><span class="swatch__value bg_color_cyan lazyload"></span></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-21.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-22.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js_addtc cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Add to cart</span><i class="iccl iccl-cart"></i><span>Add to cart</span></a></div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Black mountain hat</a></h3>
                                <span class="price dib mb__5">$50.00</span></div>
                        </div>
                    </div>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-31.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-32.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Men pants</a></h3>
                                <span class="price dib mb__5">$49.00 – $56.00</span>
                                <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid"><span data-bgset="/images/home-classic/pr-31.jpg" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right lazyload"><span class="tt_txt">Blue</span><span class="swatch__value bg_color_blue"></span></span><span data-bgset="/images/home-classic/pr-33.jpg" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right lazyload"><span class="tt_txt">Black</span><span class="swatch__value bg_color_black"></span></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-19.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-classic/pr-20.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js_addtc cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Add to cart</span><i class="iccl iccl-cart"></i><span>Add to cart</span></a></div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Cream women pants</a></h3>
                                <span class="price dib mb__5">$35.00</span></div>
                        </div>
                    </div>
                    <div class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload"><a class="d-block" href="product-detail-layout-01.html">
                                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-fashion-9/pr-s-33.jpg"></div>
                            </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" data-bgset="/images/home-fashion-9/pr-s-34.jpg"></div>
                                </div>
                                <div class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js_addtc cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Add to cart</span><i class="iccl iccl-cart"></i><span>Add to cart</span></a></div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">Sunny Life</a></h3>
                                <span class="price dib mb__5">$68.00</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end product recommendations section-->
</template>

<script>
export default {
    name: "ProductRecommendationsSection"
}
</script>

<style scoped>

</style>