<template>
    <!--product recently viewed section-->
    <div class="kalles-section tp_se_cdt">
        <div class="related product-extra mt__60 lazyload">
            <div class="container">
                <div class="wrap_title des_title_1">
                    <h3 class="section-title tc pr flex fl_center al_center fs__24 title_1"><span class="mr__10 ml__10">最近浏览的产品</span></h3>
                    <span class="dn tt_divider"><span></span><i class="dn clprfalse title_1 la-gem"></i><span></span></span><span class="section-subtitle db tc sub-title"></span>
                </div>
                <div class="sortby_3 products nt_products_holder nt_slider row row_pr_1 cdt_des_1 round_cd_false nt_cover ratio_nt position_8 space_30 prev_next_0 btn_owl_1 dot_owl_1 dot_color_1 btn_vi_1 is-draggable" data-flickity='{"imagesLoaded": 0,"adaptiveHeight": 0, "contain": 1, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": true,"prevNextButtons": false,"percentPosition": 1,"pageDots": false, "autoPlay" : 0, "pauseAutoPlayOnHover" : true, "rightToLeft": false }'>
                    <div v-for="(dt, index) in product_list" :key="index" class="col-lg-3 pr_animated col-md-3 col-6 mt__30 pr_grid_item product nt_pr desgin__1 done">
                        <div class="product-inner pr">
                            <div class="product-image pr oh lazyload">
                                <span v-if="dt.mark_title" class="tc nt_labels pa pe_none cw"><span class="nt_label" :style="dt.mark_color ? ('background-color:' + dt.mark_color) : ''">{{dt.mark_title}}</span></span>
                                <a class="d-block" :href="'/product/item/' + dt.product_id" target="_blank">
                                    <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                </a>
                                <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                    <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                </div>
                                <div v-if="false" class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                <div v-if="false" class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                            </div>
                            <div class="product-info mt__15">
                                <h3 class="product-title pr fs__14 mg__0 fwm"><a class="cd chp" :href="'/product/item/' + dt.product_id" target="_blank">{{dt.title}}</a></h3>
                                <span v-if="website.show_price" class="price dib mb__5">
                                    <template v-if="(Number(dt.market_price)) > (Number(dt.max_price))">
                                        <del class="mr__5"><span style="font-size: 12px;">¥</span> {{dt.market_price}}</del>

                                        <ins v-if="dt.min_price != dt.max_price"><span style="font-size: 12px;">¥</span> {{dt.min_price}}~{{dt.max_price}}</ins>
                                        <ins v-else><span style="font-size: 12px;">¥</span> {{dt.min_price}}</ins>
                                    </template>
                                    <template v-else>
                                        <template v-if="dt.min_price != dt.max_price">
                                            <span style="font-size: 12px;">¥</span> {{dt.min_price}}~{{dt.max_price}}
                                        </template>
                                        <template v-else>
                                            <span style="font-size: 12px;">¥</span> {{dt.min_price}}
                                        </template>
                                    </template>
                                </span>
                                <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid">
                                    <span v-for="(dt2, index2) in dt.sku_list" :key="index2" :data-bgset="dt2.img_url" class="nt_swatch_on_bg swatch__list--item position-relative ttip_nt tooltip_top_right"><span class="tt_txt">{{dt2.keys_titles}}</span><span class="swatch__value bg_color_white-cream lazyload" :data-bgset="dt2.img_url"></span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end product recently viewed section-->
</template>

<script>
import {inject, ref} from "vue";
import {idsProductV1Api} from "@/api/product/product";

export default {
    name: "ProductRecentlyViewedSection",
    setup() {

        const website = inject('website');
        const clickLikeAdd = inject('clickLikeAdd');

        const product_list = ref([]);

        const recently_viewed_product_ids = ref(localStorage.getItem('recently_viewed_product_ids'));
        const apiProducts = () => {
            return new Promise(function (resolve) {
                idsProductV1Api(recently_viewed_product_ids.value).then(res => {
                    if (res.data.code == 0) {
                        product_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        if (recently_viewed_product_ids.value) {
            // 初始数据加载
            Promise.all([apiProducts()]).then(() => {
                // apiProductList().then(() => {
                //     mainNotyConfirm.close();
                // });
            });
        }

        return {product_list, website, clickLikeAdd}
    },
}
</script>

<style scoped>
.swatch_style_rounded .swatch__list .swatch__list--item, .swatch_style_rounded .swatch__list .swatch__value {
    border-radius: 0;
}
</style>